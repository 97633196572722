.athSummaryContainer{
    margin: 20px;
    background-color: rgb(30, 81, 163);
    border-radius: 5px;
    padding: 15px;
}

.athFlexCont{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.athCard{
    height: 250px;
    width: 200px;
    background-image: url("https://nsn-assets.s3.us-east-2.amazonaws.com/images/athletes/vertical_images/NSC.jpg");
    background-position: center;
    background-color: rgba(4, 38,92, 0.95);
    background-size: cover;
    border-radius: 3px;
    border: 4px solid rgb(4, 38,92);
    box-shadow: 5px 5px 15px rgba(0,0,0,0.5);
    
}

.athInfo{
    padding-left: 50px;
}
.athName{
    font-family: "Avega-Italic";
    color: #ffffff;
    font-size: 28px;
}

.titleUnderline{
    font-family: "Avega-Italic";
    color: #ffffff;
    font-size: 28px;
    border-bottom: 1px solid #ffffff;
    width: 100%;
    margin: 20px;
}

.athSubInfo{
    font-family: "Avega-Italic";
    color: #ffffff;
    font-size: 16px;
}

.statTableDiv{
    height: 250px;
    overflow: auto;
}

.statTable{
    width: 100%;
    text-align: left;
    color: #ffffff;
}

.statTable th{
    border-bottom: 2px solid #ffffff;
}

.statTable tr:nth-child(odd){
    background-color: rgba(4, 38,92, 0.95);
}

.statTable tr:nth-child(even){
    
}

.athBioFlexCnt{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    
}

.statSection{
    margin: 10px;
    /* font-family: "Avega-Italic"; */
    font-weight: bold;
    color: #ffffff;
    font-size: 16px;
    
}

.fixedSize{
    height: 175px;
    width: 270px;
    margin: 0px 20px; 
}

.robTitle{
    color: #ffffff;
    font-family: "Avega-Italic";
    background-color: rgb(88, 185, 64);
    border-radius: 2px;
    padding: 3px 8px;
    margin: 0px 14px;
    font-size: 12px;
    width: 90px;
}

.robAthContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0px 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid white;

}

.robAthlete{
    color: #ffffff;
    font-family: "Avega-Italic";
    background-color: rgb(30, 81, 163);
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    margin: 0px 4px;
}

.robAthlete:hover{
    background-color: rgb(148,194,228);
}

.robActiveAthlete{
    background-color: rgb(88, 185, 64);
}


@media only screen and (max-width: 480px)  {
    .athInfo{
        padding-left: 0px;
    }
}