.tabContainer{
    margin: 25px 25px;
}

.tabRow{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(20,24,32);
}

.tabTitle{
    font-size: 16px;
    font-family: 'Avega-Italic';
    color: #ffffff;
    padding: 10px;
}

.tabTitle:hover{
    cursor: pointer;
    border-bottom: 2px solid var(--nsn-green);
}

.tabTitleActive{
    border-bottom: 2px solid var(--nsn-green);
}

.tabContent{
    font-family: "Montserrat-Regular";
    color: rgb(235,235,235);
    padding: 10px 10px;
    border: 1px solid rgb(20,24,32);
}

.tableContainer{
    margin: 25px;
}

.rankingTitleBox{
    background-image: radial-gradient(circle, #04265C, #04265C, #04265C, #04265C, #04265C);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2px 30px;
    }

    .rankingTitle{
        font-size: 2.5em;
        color: #ffffff;
        font-family: "Avega-Italic";
        text-align: center;
        }

        .rankingTable td{
            /* padding: 3px; */
            }
            
            .rankingTableHeader{
            background-color: #06337a;
            color: #ffffff;
            font-size: 18px;
            font-family: "Montserrat-Regular";
            }
            
            .rankingTableHeader th{
            padding: 5px;
            }
            
            .rankCell{
            background-color: #04265C;
            color: #ffffff;
            font-weight: bold;
            font-family: "Avega-Italic";
            width: 60px;
            font-size: 20px;
            
            }
            .nameCell{
            background-color: #f1f1f1;
            color: #04265C;
            font-family: "Avega-Italic";
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden; 
            font-size: 20px;
            display: flex;
            align-items: center;
            }
            .locCell{
            background-color: #94C2E4;
            color: #04265C;
            font-weight: bold;
            font-family: "Avega-Italic";
            font-size: 20px;
            }
            .timeCell{
            background-color: #66D849;
            color: #04265C;
            font-weight: bold;
            font-family: "Avega-Italic";
            font-size: 20px;
            }

.rankingMobileTable{
    display: none;
}

.mobileNameRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.responsiveContainer{
    margin: 4px 0px;
}

.mobileDataRow{
    display: flex;
    align-items: center;
}

.mobileTitleCell{
    background-color: #04265C;
    color: #ffffff;
    font-weight: bold;
    font-family: "Montserrat-Regular";
    font-size: 10px;
    height: 35px;
    width: 50px;
    text-align: center;
    /* flex: 1; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileDataCell{
    background-color: #94C2E4;
            color: #04265C;
            font-weight: bold;
            font-family: "Montserrat-Regular";
            font-size: 10px;
            height: 35px;
            flex: 1;
            display: flex;
    align-items: center;
    justify-content: center;
}

.mobileRankCell{
    background-color: #04265C;
            color: #ffffff;
            font-weight: bold;
            font-family: "Avega-Italic";
            width: 45px;
            font-size: 20px;
            display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.mobileNameCell{
    background-color: #f1f1f1;
    color: #04265C;
    font-family: "Avega-Italic";
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
    font-size: 20px;
    display: flex;
    align-items: center;
    height: 50px;
    }

    .updatedTitle{
        color: #ffffff;
    }

    .tabTitleGen{
        font-size: 40px;
        font-family: 'Avega-Italic';
        color: #ffffff;
        padding: 10px;
    }
    
    .tabTitleGen:hover{
        cursor: pointer;
        border-bottom: 2px solid var(--nsn-green);
    }

@media only screen and (max-width: 765px)  {
 .tabTitle{
    font-size: 14px;
 } 

 .tabTitleGen{
    font-size: 14px;
 } 

.rankingTable{
    display: none;
}

.rankingMobileTable{
    display: block;
}

.rankingTitle{
    font-size: 18px;
}

}