.Stopwatch{
    background-color: var(--nsn-dark-card);
    padding: 5px;
    border-radius: 3px;
    color: rgb(200,200,200);
  }
  .timerBox{
    margin: 20px 0px;
  }

  .SWInnerBox{
    margin-top: 20px;
  }
  
  .Timer {
      text-align: center;
      /* font-size: 40px; */
      padding: 0px;
    }
  
    .Controls {
      text-align: center;
    }
    
    .startBtn{
      margin-top: 25px;
      color: #ffffff;
      background-color: rgba(91, 183,91,0.9);
      width: 100%;
      border: none;
      border-radius: 3px;
      padding: 1em;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
  }
  
  .startBtn:hover{
      background-color: rgba(255,255,255,0.8);
      color: rgb(91, 183,91);
      transition: 200ms;
  }
  
  .stopBtn{
      margin-top: 25px;
      color: #ffffff;
      background-color: var(--nsn-grey);
      width: 100%;
      border: none;
      border-radius: 3px;
      padding: .5em;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
  }
  
  .stopBtn:hover{
      background-color: rgba(255,255,255,0.8);
      color: rgb(216, 83,78);
      transition: 200ms;
  }
  
    .LapTime {
      text-align: center;
    }
    
    .LapTime__lap, .LapTime__time {
      width: 50%;
      padding: 5px;
      text-align: center;
      display: inline-block;
      box-sizing: border-box;
    }
    .LapTimeList__list {
      list-style: none;
      padding: 0px;
      margin: 15px auto;
    }
    
    .LapTimeList__listwrap {
      max-width: 270px;
      margin: 0 auto;
    }
    
    .LapTimeList__headers {
      width: 100%;
    }
    
    .LapTimeList__headers span {
      width: 50%;
      font-weight: bold;
      text-align: center;
      display: inline-block;
      float: left;
    }
    .Stopwatch {
      text-align: center;
    }
  
    .SWBoxTitle{
      font-size: 28px;
      /* border-bottom: 2px solid #bdbdbd; */
      /* background-color: rgb(0,90,180); */
      padding: 5px;
      color: rgb(97,218,251);
      font-weight: bold;
      border: 2px solid rgb(97,218,251);
      border-radius: 3px;
  
  }
  .SWBoxContentClear{
      font-size: 56px;
      padding: 50px 25px 50px 25px;
      margin: 10px 15px;
      cursor: pointer;
      border-radius: 3px;
      color: rgb(255,255,255);
      background-color: rgba(91, 183,91,0.9);
      width: 100%;
  }
  
  .SWBoxContentFail{
    font-size: 30px;
    padding: 10px 15px 10px 15px;
    margin: 10px 15px 30px 15px;
    cursor: pointer;
    border-radius: 3px;
    color: rgb(255,255,255);
    background-color: rgb(216, 83,78); 
  }
  
  
  .resetBox{
    color: rgb(199, 78, 78);
    border: 4px solid rgb(199, 78, 78);
    border-radius: 2px;
    margin: 10px 0px;
    padding: 5px 0px;
  }