.resultsEntry{
    height: 100vh;
    overflow: auto;
}
.mainBack{
    /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../static/chicago_gym.jpg'); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: scroll;
}

.pageContainer{
    margin-left: 25px;
    margin-right: 25px;
    width: 100%;
    margin-bottom: 100px;
    overflow-y: auto;
}

.subTitle{
    font-size: 24px;
    margin-top: 20px;
    color: var(--nsn-grey);
    font-weight: bold;
}

.activeBox{
    background-color: rgba(0,90,180,0.5);
    color: #ffffff;
}

.athleteBox{
    font-size: 1.5em;
    background-color: rgb(68, 73, 87);
    border: #979797;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.6);
    transition: 0.3s;
    margin-top: 10px;
    color: #dadada;
}

.athleteBox:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    background-color: rgb(99, 106, 128);
  }

  .completed{
    background-color: #49c5b1; /* or 48BF84 */
    color: #ffffff;
}

.scName{
    /* background-color: #ffffff; */
    /* border: 2px solid rgb(200,200,200); */
    padding: 5px;
    font-size: 1.5em;
    font-weight: bold;
    margin-left: 10px;
    color: #ffffff;
}

.obstacleBox{
    min-width: 300px;
    background-color: rgb(68, 73, 87);
    border: #979797;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.6);
    transition: 0.3s;
    margin-top: 10px;
    color: #dadada;
    font-weight: bold;
}
.obstacleBoxTitle{
    font-size: 18px;
    border-bottom: 2px solid #a1a1a1;
    background-color: rgb(52, 55, 66);
    padding: 5px;
}
.obstacleBoxContent{
    font-size: 24px;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
}
.obstacleBoxContent:hover{
    background-color: rgb(99, 106, 128);
}

.activeClear{
    background-color: rgba(88,219,136, 0.7);
    color: #ffffff;
}

.activeFail{
    background-color: rgba(238,95,85,0.7); 
    color: #ffffff;
}

.skillInput{
    background-color: rgb(68, 73, 87);
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 5px;
    border: 2px solid rgb(139, 139, 139);
    color: #ffffff
}
.submitBtn{
    margin-top: 25px;
    margin-bottom: 100px;
    color: #ffffff;
    background-color: rgba(0, 92,180,0.9);
    width: 100%;
    border: none;
    border-radius: 3px;
    padding: .5em;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.submitBtn:hover{
    background-color: rgba(255,255,255,0.8);
    color: rgb(0, 92,180);
    transition: 200ms;
}

.updateBtn{
    margin-top: 25px;
    margin-bottom: 100px;
    color: #ffffff;
    background-color: rgba(91, 183,91,0.9);
    width: 100%;
    border: none;
    border-radius: 3px;
    padding: .5em;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.updateBtn:hover{
    background-color: rgba(255,255,255,0.8);
    color: rgb(91, 183,91);
    transition: 200ms;
}

.leaderboardDivision{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.athleteDivision{
    flex-grow: 1;
    margin: 0px 10px;
}

.scrollLink{
    text-align: center;
    padding: 10px;
    display: none;
    color: rgb(0,90,180);
    cursor: pointer;
    font-size: 1.2em;
    text-decoration: underline;
}

.tieTime{
    font-size: .8em;
    flex-grow: 1;
}

.backIcon{
    color: rgb(0,90,180);
    cursor: pointer;
    margin: 0px 10px;
}

.scoreboardHeader{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-bottom: 20px;
}

.hideMobile{
    display: block;
}

.liveBanner{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    color: #ffffff;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
}

.liveContent{
    padding: 10px;
}

.failIcon{
    color: rgb(216, 83,78);
    margin: 0px 5px;
}

.clearIcon{
    color: rgba(91, 183,91,0.9);
    margin: 0px 5px;
}

.noBtn{
    margin-top: 25px;
    margin-bottom: 100px;
    color: #ffffff;
    background-color: rgb(216, 83,78);
    width: 100%;
    border: none;
    border-radius: 3px;
    padding: .5em;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.noBtn:hover{
    background-color: rgba(255,255,255,0.8);
    color: rgb(216, 83,78);
    transition: 200ms;
}

.reTitle{
    color: #ffffff;
    font-size: 28px;
    font-weight: bold;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #f32121;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #f32121;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

@media only screen and (max-width: 600px){
    .leaderboardDivision{
        flex-wrap: wrap;
    }
    .scrollLink{
        display: block;
    }
    .hideMobile{
        display: none;
    }
}