.blockTitle{
    color: #ffffff;
    font-family: "Avega-Italic";
    font-size: 24px;
    border-bottom: 1px solid rgb(102, 216, 73);
}

.blockSubTitle{
    color: #ffffff;
    font-family: "Avega-Italic";
    font-size: 16px;
}

.baseText{
    font-family: "Montserrat-Regular";
    color: #ffffff;
}

.nscBtn{
    margin-top: 25px;
    color: #ffffff;
    background-color: rgb(102, 216, 73);;
    width: 100%;
    border: none;
    border-radius: 3px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nscBtn:hover{
    background-color: rgba(255,255,255,0.8);
    color: var(--nsn-dark-blue);
    transition: 200ms;
}

.flex-container-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}


@media only screen and (max-width: 500px)  {
    
}