body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'AVEGA';
  src: local('Avega'), url(./fonts/Avega.ttf) format('truetype');
}

@font-face {
  font-family: 'AVEGA-ITALIC';
  src: local('Avega-Italic'), url(./fonts/Avega-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PizzaismyFAVORITE';
  src: local('PizzaismyFAVORITE'), url(./fonts/PizzaismyFAVORITE.ttf) format('truetype');
}

:root{
  --nsn-dark-blue: rgb(4,38,92);
  --nsn-light-blue: rgb(148,194,228);
  --nsn-grey: rgb(132, 134, 140);
  --nsn-green: rgb(102,216,73);
  --nsn-dark-card: rgb(68, 73, 87);
}