.timerContain{
    padding-left: 80px;
    padding-top: 30px;
    letter-spacing: 5px;
    width: 144px;
}

.nameContain{
    /* padding-left: 80px; */
    /* padding-top: 30px; */
    letter-spacing: 5px;
    font-size: 75px;
    text-align: center;
    width: 1086px;
}

.liveClock{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    /* background-color: rgba(0,0,0,0.8); */
    margin-top: 50px;
  }
  
  .LCtextBox{
    display: flex;
    flex-direction: column;
    padding: 25px 35px;
  }
  
  .LCtextTitle{
    font-family: "Avega-Italic";
    font-size: 24px;
    color: #ffffff;
    /* padding: 10px 25px 0px 20px; */
    width: 300px;
  }
  
  .LCtextSubTitle{
    color: rgb(97,218,251);
    font-size: 20px;
    padding: 0px 25px 10px 20px;
  }
  
  .LCclockBox{
    font-family: "Arial Black";
    font-weight: bold;
    background: linear-gradient(rgb(59, 129, 42), rgb(102, 216, 73),rgb(56, 122, 40));
    color: #000000;
    font-size: 24px;
    padding: 5px 0px;
    width: 105px;
  }
  
  .LCclockBoxFail{
    background-color: rgb(185, 7, 7);
    color: #ffffff;
    font-size: 40px;
    padding: 10px 20px;
  }
  
  .liveClockTable{
    /* background-color: rgb(0,0,0,0.8); */
    /* border-left: 5px solid rgb(97,218,251); */
    margin-top: 50px;
  }
  
  .lowerThirdTable{
    background-image: radial-gradient(circle, #000000, #171416, #242225, #313036, #3c4048);
    border-left: 5px solid rgb(97,218,251);
    border-right: 5px solid rgb(243, 239, 20);
    margin-top: 50px;
  }
  
  .LTtextTitle{
    font-size: 26px;
    color: #ffffff;
    padding: 20px 25px 0px 25px;
    height: 75px;
    overflow: hidden;
  }
  
  .LTtextSubTitle{
    color: rgb(97,218,251);
    font-size: 18px;
    padding: 0px 25px 20px 25px;
  }
  
  .LTimg{
    padding: 0px 25px;
  }
  
  .LCBody{
    padding: 20px;
  }


  .LCNameRow{
      /* box-shadow: inset 0px 0px 20px 10px rgba(0,0,0,0.6); */
      background: linear-gradient(rgb(20,20,20), rgb(50,50,50), rgb(20,20,20));
  }

  .LCTickBox{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 0px 20px 10px rgba(0,0,0,0.6);
    background: linear-gradient(rgb(20,20,20), rgb(50,50,50), rgb(20,20,20));
    width: 100%;
    padding: 2px 0px;
    height: 18px;
  }

  .lcThreeStrikeTD{
    box-shadow: inset 0px 0px 20px 10px rgba(0,0,0,0.6);
    background: linear-gradient(rgb(20,20,20), rgb(50,50,50), rgb(20,20,20));
    padding: 0px 3px;
  }


.threeStrikeIconDiv{
    margin: 5px 0px;
      width: 3px;
      height: 12px;
      background: rgba(50,50,50, 1);
  }

  .LCThreeStrikeIcon{
    /* color: var(--nsn-green); */
    background: rgb(255, 56, 56);
    box-shadow: 0px 0px 10px 1px rgb(199, 40, 40);

}

  .LCLogoBox{
    background: linear-gradient(rgb(4,38,92), rgb(54, 97, 165), rgb(4,38,92));
    text-align: center;
    width: 105px;
  }

  .obstacleIcon{
      margin: 0px 5px;
      color:rgba(50,50,50, 1);
  }

  .obstacleIconDiv{
    margin: 0px 5px;
      width: 12px;
      height: 3px;
      background: rgba(50,50,50, 1);
  }

  .LCclearIcon{
      /* color: var(--nsn-green); */
      background: var(--nsn-green);
      box-shadow: 0px 0px 10px 1px var(--nsn-green);
  }

  .LCfailIcon{
    /* color: var(--nsn-green); */
    margin: 0px 5px;
      /* width: 12px; */
      /* height: 3px;  */
    /* background: rgb(199, 40, 40); */
    color: rgb(255, 56, 56);
    /* box-shadow: 0px 0px 10px 1px rgb(199, 40, 40); */
}

.courseBox{
    background: linear-gradient(rgb(20,20,20), rgb(50,50,50), rgb(20,20,20));
    color: #ffffff;
    font-family: 'Avega-Italic';
    font-size: 14px;
}

.RescourseBox{
  background-color: rgb(4, 21, 49);
  color: #ffffff;
  font-family: 'Avega-Italic';
  font-size: 14px;
}

.avega-white{
    color: rgb(150,150,150);
    font-family: 'Avega-Italic';
}

.resetTime{
    background: linear-gradient(rgb(20,20,20), rgb(50,50,50), rgb(20,20,20));
    box-shadow: inset 0px 0px 20px 10px rgba(0,0,0,0.6);
    color: rgb(199, 40, 40);
    font-family: 'Arial Black';
    font-size: 24px;
}

  td{background-color: rgba(255,255,255, 0);}




  .LCleaderboardDivision{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 35px;
    width: 200px;
    }
    
    .LCathleteDivision{
    /* flex-grow: 0; */
    /* margin: 0px 5px; */
    /* width: 1280px;
    height: 720px;
    overflow: hidden; */
    display: flex;
    flex-direction: row;
    }
    
    .LCscoreboardTitleBox{
    /* background-image: radial-gradient(circle, #04265C, #04265C, #04265C, #04265C, #04265C); */
    background-color: rgba(4, 38,92, 0.95);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    }

    .miniScoreboard{
      margin: 0px 10px;
    }
    
    
    .LCscoreboardSubTitle{
    font-size: 1.4em;
    color: #66D849;
    font-family: "Avega-Italic";
    }
    
    .LCscoreboardTable td{
    padding: 3px;
    }
    
    .LCtableHeader{
    background-color: rgba(4, 38,92, 0.95);
    color: #ffffff;
    font-size: 14px;
    font-family: "Avega-Italic";
    }
    
    .LCtableHeader th{
    padding: 5px;
    }
    
    .LCrankCell{
    background-color: rgba(4, 38,92, 0.95);
    color: #ffffff;
    font-weight: bold;
    font-family: "Avega-Italic";
    width: 10px;
    }
    .LCnameCell{
    background-color: rgba(240,240,240, 0.95);
    color: #04265C;
    font-family: "Avega-Italic";
    text-align: left;
    width: 170px;
    }
    .LClocCell{
    background-color: rgba(148,194,228, 0.95);
    color: #04265C;
    font-weight: bold;
    font-family: "Avega-Italic";
    }
    .LCtimeCell{
    background-color: rgba(102,216,73, 0.95);
    color: #04265C;
    font-weight: bold;
    font-family: "Avega-Italic";
    }

    .LCathleteBox{
        cursor: pointer;
        text-align: center;
        /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.6); */
        font-size: 17px;
    }
    
    .resOvrContain{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 50px;
    }

    .resOvrStatContain{
      text-align: center;
    }

    .resOvrStatTitle{
      color: #ffffff;
      font-family: 'Avega-Italic';
      font-size: 16px;
    }

    .resOvrStat{
      color: #ffffff;
      font-family: 'Avega-Italic';
      font-size: 20px;
    }

    .resTDBox{
      background: rgb(30, 81, 163);
    }

    .nsnBtn{
      color: #ffffff;
      background-color: rgba(0, 92,180,0.9);
      border: none;
      border-radius: 3px;
      padding: .5em;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }

    .V2LCContainer{
      display: flex;
      flex-direction: 'row';
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
    }

    .V2LCLogoBox{
      width: 105px;
      background-color: rgb(4, 21, 49);
      text-align: center;
      height: 70px;
    }

    .V2InfoBox{
      color: #ffffff;
      overflow: hidden;
      text-align: center;
      font-family: "Avega-Italic";
    }

    .V2LCclockBox{
      font-family: "Arial Black";
      font-weight: bold;
      /* background: linear-gradient(rgb(59, 129, 42), rgb(102, 216, 73),rgb(56, 122, 40)); */
      background-color: rgb(4, 21, 49);
      color: #ffffff;
      font-size: 24px;
      padding: 0px 0px;
      width: 105px;
      height: 70px;
    }

    .V2AthleteBox{
      display:flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(30, 81, 163);
      height: 40px;
      width: 300px;
      padding: 0px 0px;
      font-size: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; 
    }

    .V2LCObstacles{
      background-color: rgb(4, 21, 49);
      height: 30px;
      width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      padding: 0px 0px;
      font-size: 12px;
    }

    .V2obstacleIconDiv{
      border: 1px solid #ffffff;
      height: 15px;
      width: 15px;
      /* background-color: rgb(77, 77, 77); */
    }

    .V2LCclearIcon{
      background-color: rgb(88, 185, 64);
    }

    .V2LCfailIcon{
      background-color: rgb(255, 56, 56);
    }

    .V2Fails{
      display: flex;
      justify-content: center;
      font-size: 14px;
      background-color: rgb(4, 21, 49);
      color: #ffffff;
      font-family: "Avega-Italic";
      height: 30px;
      align-items: center;
    }

    .V2FailCnt{
      color:rgb(255, 56, 56);
    }

    .V2ResetTime{
      color: rgb(199, 40, 40);
    font-family: 'Arial Black';
    font-size: 18px;
    height: 20px;
    display: flex;
      justify-content: center;
      align-items: center;
    }

    .V2ThreeStrikes{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      height: 20px;
    }

    .V2Timer{
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }


  /* Version 3 START xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */
  .V3LCContainer{
    display: flex;
    flex-direction: 'row';
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }

  .V3LCLogoBox{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 105px;
    background-color: rgb(4, 21, 49);
    text-align: center;
    height: 48px;
  }

  .V3InfoBox{
    color: #ffffff;
    overflow: hidden;
    text-align: center;
    font-family: "Avega-Italic";
  }

  .V3LCclockBox{
    font-family: "Arial Black";
    /* font-weight: bold; */
    /* background: linear-gradient(rgb(59, 129, 42), rgb(102, 216, 73),rgb(56, 122, 40)); */
    background-color: rgb(4, 21, 49);
    color: #ffffff;
    font-size: 24px;
    padding: 0px 0px;
    /* width: 105px; */
    height: 48px;
  }

  .V3AthleteBox{
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(30, 81, 163);
    height: 48px;
    width: 300px;
    padding: 0px 0px;
    font-size: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
  }

  .V3LCObstacles{
    background-color: rgb(4, 21, 49);
    height: 24px;
    width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 0px;
    font-size: 14px;
  }

  .V3obstacleIconDiv{
    border: 1px solid #ffffff;
    height: 15px;
    width: 15px;
    /* background-color: rgb(77, 77, 77); */
  }

  .V3LCclearIcon{
    background-color: rgb(88, 185, 64);
  }

  .V3LCfailIcon{
    background-color: rgb(255, 56, 56);
  }

  .V3Fails{
    display: flex;
    justify-content: center;
    font-size: 14px;
    background-color: rgb(4, 21, 49);
    color: #ffffff;
    font-family: "Avega-Italic";
    height: 24px;
    align-items: center;
  }

  .V3FailCnt{
    color:rgb(255, 56, 56);
  }

  .V3ResetTime{
    color: rgb(199, 40, 40);
  font-family: 'Arial Black';
  font-size: 18px;
  height: 12px;
  display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
  }

  .V3ThreeStrikes{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    height: 12px;
  }

  .V3Timer{
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .V3DataContainer{
    display: flex;

  }
  /* Version 3 END xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */