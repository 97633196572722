.darkChoiceBox{
    width: 150px;
    background-color: rgb(68, 73, 87);
    border: #979797;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.6);
    transition: 0.3s;
    margin-top: 10px;
    color: #dadada;
    font-weight: bold;
}

.darkChoiceBox:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    background-color: rgb(99, 106, 128);
  }

.darkCard{
    background-color: rgb(68, 73, 87);
    border: #979797;
    padding: 20px;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,.6);
    transition: 0.3s;
    margin-top: 10px;
    color: #dadada;
    font-weight: bold;
}

.courseObstacleBox{
    background-color: #ffffff;
    border: #979797;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin-top: 15px;
    font-size: 1.5em;
    width: 100%;
}

.courseObstacleBox:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    background-color: rgb(230,230,230);
  }

  .courseInputBox{
      font-size: 28px;
      border: none;
      padding: 10px;
  }

  .darkCardTitle{
      font-size: 28px;
  }

  .fieldLabel{
      margin-right: 10px;
  }

  .field{
      margin-left: 10px;
      color: #ffffff;
  }