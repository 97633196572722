.timerContain{
    padding-left: 80px;
    padding-top: 30px;
    letter-spacing: 5px;
    width: 144px;
}


    .NMLCContainer{
      display: flex;
      flex-direction: 'row';
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
    }

    .NMLCLogoBox{
      width: 105px;
      background-color: rgb(4, 21, 49);
      text-align: center;
      height: 70px;
    }

    .NMInfoBox{
      color: #ffffff;
      overflow: hidden;
      text-align: center;
      font-family: "Avega-Italic";
    }



    .NMAthleteBox{
      display:flex;
      align-items: center;
      justify-content: center;
      /* background-color: rgb(30, 81, 163); */
      /* height: 40px; */
      min-width: 250px;
      font-family: "Franklin Gothic Heavy";
      color: #ffffff;
      padding: 0px 0px;
      font-size: 55px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; 
    }

    .NMPlacementBox{
        text-align: center;
        /* background-color: rgb(30, 81, 163); */
        /* height: 40px; */
        width: 300px;
        font-family: "Franklin Gothic Heavy";
        color: #ffffff;
        padding: 0px 0px;
        font-size: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; 
      }

      .NMClockBox{
        display:flex;
        align-items: center;
        justify-content: center;
        /* background-color: rgb(30, 81, 163); */
        /* height: 40px; */
        width: 300px;
        font-family: "Franklin Gothic Heavy";
        color: #ffffff;
        padding: 0px 0px;
        font-size: 55px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; 
      }

    .NMClockBackground{
        width: 1920px;
        height: 120px;
        background-image: linear-gradient(to right, #0b013a,#0b013a, #ed1c24, #0b013a, #0b013a);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;
        margin: 20px 0px;
    }

/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
Bucket of Chalk
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */

    .BOCAthleteBox{
      display:flex;
      align-items: center;
      justify-content: center;
      /* background-color: rgb(30, 81, 163); */
      /* height: 40px; */
      min-width: 250px;
      font-family: "PizzaismyFAVORITE";
      font-weight: bold;
      color: #ffffff;
      padding: 0px 0px;
      font-size: 55px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; 
    }

    .BOCPlacementBox{
        text-align: center;
        /* background-color: rgb(30, 81, 163); */
        /* height: 40px; */
        width: 300px;
        font-family: "PizzaismyFAVORITE";
        color: #ffffff;
        padding: 0px 0px;
        font-size: 24px;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; 
      }

      .BOCClockBox{
        display:flex;
        align-items: center;
        justify-content: center;
        /* background-color: rgb(30, 81, 163); */
        /* height: 40px; */
        width: 300px;
        /* font-family: "Franklin Gothic Heavy"; */
        font-family: "PizzaismyFAVORITE";
        color: #ffffff;
        padding: 0px 0px;
        font-size: 55px;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; 
      }

    .BOCClockBackground{
        width: 1920px;
        height: 120px;
        background-image: linear-gradient(to right, #131313,#131313, #474747, #131313, #131313);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;
        margin: 20px 0px;
    }