/* .headerContainer{
    position: sticky;
    top: 0;
    z-index: 900; 
} */

.mainDiv{
    background-color: rgb(40,44,52);
    min-height: 100vh;
}

/* .headerSection{
    background-color: rgba(10,10,10,0.8);
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 15px;
} */

/* .menuItems{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 50px;
}

.headerLink{
    text-decoration: none;
    color: rgb(148, 194,228);
    margin: 0px 10px;
    cursor: pointer;
}

.headerLink:hover{
    color: rgb(102, 216, 73);
    transition: .3s ease;
} */

.footerSection{
    background-color: rgb(20,24,32);
    color: #ffffff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 35px 0px 35px 0px;
    /* position: absolute; */
    bottom: 0;
    width: 100%
}

.footerLink{
    color: #ffffff;
    text-decoration: none;
}

.linkDiv{
    margin: 20px;
}

.footerLink:hover{
    color: rgb(102, 216, 73);
    text-decoration: none;
}




/* Home page CSS */

.flex-container-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.fullscreenVideo{
    position: relative;
    width: 100%;
    max-height: 600px;
    overflow: hidden;
    text-align: center;
}

#mainVideo{
    object-fit: contain;
}

.flex-center-center{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.videoMask{
    position: absolute;
    background-color: rgb(0,0,0,0.5);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
}

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    width: 100%; 
  } 
    
.embed-container iframe, .embed-container object, .embed-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  z-index: 1;
}

.videoTitle{
    font-family: "Avega-Italic";
    color: #ffffff;
    font-size: 64px;
    
}

.videoLink{
    font-family: "Avega";
    border: 1px solid rgb(148,194,228);
    border-radius: 2px;
    padding: 15px;
    color: rgb(148,194,228);
    font-size: 32px;
    text-decoration: none;
}

.videoLink:hover{
    background-color: rgba(255,255,255,0.3);
    cursor: pointer;
}

.appHighlights{
    background: rgb(4,38,80);
background: radial-gradient(circle, rgba(4,38,80,1) 0%, rgba(8,49,99,1) 35%, rgba(11,56,111,1) 100%);
    padding: 20px;
    border-radius: 2px;
}

.blueGradient{
    background: radial-gradient(circle, rgba(4,38,80,1) 0%, rgba(8,49,99,1) 35%, rgba(11,56,111,1) 100%);
}

.sectionTitle{
    font-family: "Avega";
    color: #ffffff;
    font-size: 2em;
    font-weight: bold;
}

.sectionDesc{
    font-family: "Avega";
    font-size: 18px;
    color: rgb(205,205,205);
    /* font-weight: bold; */
}

/* #actionPhoto{
    height: 400px;
    object-fit: cover;
}

.container {
    position: relative;
    text-align: center;
    color: white;
  }

  .overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6), rgba(0,0,0,0.9));
    display:flex;
    justify-content: center;
    align-items: center;
  } */

  .gymDiv{
    background-image: linear-gradient( rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../static/dark_bg.jpg");
    padding: 30px 20px;
    background-size: cover;
    background-position: center;
  }

  .NSCTitle{
      color: #ffffff;
      font-family: "Avega-Italic";
      font-size: 48px;
      border-bottom: 1px solid rgb(102, 216, 73);
  }

  .upcoming{
      margin-top: 30px;
    color: #ffffff;
    font-family: "Avega";
    font-size: 32px;
    color: rgb(102, 216, 73);
}

  .flex-row{
      display: flex;
  }

  .registerCard{
      background-color: rgba(255,255,255, 0.5);
      border-radius: 3px;
      padding: 10px;
      margin: 10px 0px;
  }

  .cardTitle{
      margin-left: 25px;
  }

  .gymName{
      font-family: "Avega-Italic";
      font-size: 40px;
      color: rgb(4,38,92);
      margin-bottom: 20px;
  }

  .gymInfo{
    font-family: "Avega";
    font-size: 21px;
    color: rgb(4,38,92);
}

.registerButton{
    background-color: rgb(76, 172, 51);
    color: #ffffff;
    font-family: "Avega";
    border-radius: 3px;
    margin-right: 10px;
    padding: 15px;
    font-size: 24px;
    text-decoration: none;
    width: 190px;
}

.registerButton:hover{
    cursor: pointer;
    background-color: rgb(148,194,228);
}

.registerButtonGrey{
    background-color: rgb(102, 104, 110);
    color: #ffffff;
    font-family: "Avega";
    border-radius: 3px;
    margin-right: 10px;
    padding: 15px;
    font-size: 24px;
    text-decoration: none;
    width: 190px;
}

.registerButtonRed{
    background-color: #D9534F;
    color: #ffffff;
    font-family: "Avega";
    border-radius: 3px;
    margin-right: 10px;
    padding: 15px;
    font-size: 24px;
    text-decoration: none;
    width: 190px;
}

.priceCell{
    margin-right: 50px;
}

.pulseImg{
    animation: Pulsate 2s linear infinite;
  }


.pageContainer{
    margin-left: 25px;
    margin-right: 25px;
    /* margin-bottom: 100px; */
    /* overflow-y: auto; */
}

.submitBtn{
    margin-top: 25px;
    margin-bottom: 100px;
    color: #ffffff;
    background-color: rgb(124, 162, 192);
    width: 100%;
    border: none;
    border-radius: 3px;
    padding: .5em;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.submitBtn:hover{
    background-color: rgba(255,255,255,0.8);
    color: rgb(148,194,228);
    transition: 200ms;
}

/* Hero Image CSS */
.heroImgContainer{
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden;
    text-align: center;
}

.heroMask{
    position: absolute;
    background: linear-gradient(rgba(4,38,92,0.2), rgba(4,38,92,0.9));
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.heroTitle{
    font-family: "Avega-Italic";
    color: #ffffff;
    font-size: 64px;
    /* border-bottom: 1px solid var(--nsn-green); */
}


.infoBanner{
    background: radial-gradient(circle, rgb(15, 62, 119) 0%, rgba(8,49,99,1) 35%, rgba(15, 62, 119) 100%);
    padding: 15px;
    font-family: "Avega-Italic";
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    /* text-decoration: underline; */
    /* text-decoration-color: rgb(124, 162, 192); */
}

@media only screen and (max-width: 500px)  {
    .fullscreenVideo{
        width: 100vw;
    }
    .homeContent{
        width: 100vw;
    }

    #actionPhoto{
        height: 400px;
        object-fit: cover;
    }

    .NSCTitle{
        font-size: 21px;
    }

    .videoTitle{
        font-size: 21px;
    }

    .videoLink{
        font-size: 15px;
    }

    .sectionDesc{
        font-size: 14px;
    }

    .gymName{
        font-size: 16px;
    }

    .gymInfo{
        font-size: 15px;
    }
    .cardTitle{
        margin-bottom: 20px;
    }

    .registerButton{
        font-size: 21px;
    }
    
    .registerButtonGrey{
        font-size: 21px;
    }

    .priceCell{
        margin-right: 10px;
    }
    
    }

    .scoreTable th{
        padding: 0px 5px;
    }
    .scoreTable{
        border-collapse:collapse;
        border:1px solid #7c7c7c;
        }
    .scoreTable td{
        padding: 0px 5px;
        border:1px solid #7c7c7c;
    }



    @media only screen and (max-width: 765px)  {
        .heroImgContainer{
            height: 200px;
        }
    
        .heroTitle{
            font-size: 20px;
        }
    }

/* Rules Page */

.rulesContainer{
    font-family: "Montserrat-Regular";
    color: #ffffff;
    margin: 20px 10px;
}


/* Navigation Bar */

    .topnav {
        overflow: hidden;
        /* background-color: #333; */
        background-color: var(--nsn-dark-blue);
        font-family: "Montserrat-Regular";
      }
      
      .topnav a {
        float: left;
        display: block;
        color: #f2f2f2;
        text-align: center;
        padding: 14px 16px;
        /* margin: 14px 16px; */
        text-decoration: none;
        font-size: 17px;
      }
      
      .active {
        /* background-color: #04AA6D; */
        /* background-color: var(--nsn-green); */
        border-bottom: 3px solid var(--nsn-green);
        color: white;
      }
      
      .topnav .icon {
        display: none;
      }
      
      .dropdown {
        float: left;
        overflow: hidden;
      }
      
      .dropdown .dropbtn {
        font-size: 17px;    
        border: none;
        outline: none;
        color: white;
        padding: 14px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
      }
      
      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1000;
      }
      
      .dropdown-content a {
        float: none;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
      }
      
      .topnav a:hover {
        /* background-color: #555; */
        border-bottom: 3px solid var(--nsn-grey);
        /* transition: 0.5s; */
        color: white;
      }
      
      .dropdown-content a:hover {
        /* background-color: #ddd; */
        color: black;
      }

      .dropItem:hover{
        border-left: 6px solid var(--nsn-green);
        border-bottom: 0px !important;
        background-color:rgba(15, 62, 119, 0.2);
      }
      
      .dropdown:hover .dropdown-content {
        display: block;
      }
      
      @media screen and (max-width: 765px) {
        .topnav a:not(:first-child), .dropdown .dropbtn {
          display: none;
        }
        .topnav a.icon {
          float: right;
          display: block;
        }
      }
      
      @media screen and (max-width: 765px) {
        .topnav.responsive {position: relative;}
        .topnav.responsive .icon {
          position: absolute;
          right: 0;
          top: 0;
        }
        .topnav.responsive a {
          float: none;
          display: block;
          text-align: left;
        }
        .topnav.responsive .dropdown {float: none;}
        .topnav.responsive .dropdown-content {position: relative;}
        .topnav.responsive .dropdown .dropbtn {
          display: block;
          width: 100%;
          text-align: left;
        }
      }