/* .scoreboard{
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('../static/scoreboard_backplate.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: scroll;
} */

/* .athleteBox{
background-color: #ffffff;
border: #979797;
cursor: pointer;
padding: 20px;
text-align: center;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
transition: 0.3s;
margin-top: 15px;
font-size: 1.5em;
} */

.leaderboardDivision{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
margin: 5px 25px;
}

.athleteDivision{
flex-grow: 1;
margin: 0px 10px;
/* width: 1280px;
height: 720px;
overflow: hidden; */
}

.legend{
    color: #ffffff;
    font-family: "Avega-Italic";
}

.overallTable{
    border-collapse: separate;
    border-spacing: 0 5px;
}

.scoreboardTitleBox{
background-image: radial-gradient(circle, #04265C, #04265C, #04265C, #04265C, #04265C);
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 2px 30px;
}

.scoreboardTitle{
font-size: 2.5em;
font-weight: bold;
color: #ffffff;
font-family: "Avega-Italic";
text-align: center;
border-bottom: 1px solid #66D849;
}

.scoreboardSubTitle{
font-size: 1.4em;
color: #66D849;
font-family: "Avega-Italic";
}

.scoreboardTable td{
padding: 3px;
}

.tableHeader{
background-color: #06337a;
color: #ffffff;
font-size: 22px;
font-family: "Avega-Italic";
}

.tableHeader th{
padding: 5px;
}

.rankCell{
background-color: #04265C;
color: #ffffff;
font-weight: bold;
font-family: "Avega-Italic";
width: 60px;
font-size: 20px;
}
.nameCell{
background-color: #f1f1f1;
color: #04265C;
font-family: "Avega-Italic";
text-align: left;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden; 
font-size: 20px;
}
.locCell{
background-color: #94C2E4;
color: #04265C;
font-weight: bold;
font-family: "Avega-Italic";
font-size: 20px;
}
.timeCell{
background-color: #66D849;
color: #04265C;
font-weight: bold;
font-family: "Avega-Italic";
font-size: 20px;
}

.showBackground{
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('../static/dark_bg.jpg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 100vh;
        overflow: scroll;
}

.qualifiedStamp{
    font-size: 12px;
    font-weight: bold;
}

.resultRow{
    font-size: 1.5em;
    background-color: rgb(68, 73, 87);
    border: #979797;
    cursor: pointer;
    padding: 20px;
    text-align: center;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,.6); */
    transition: 0.3s;
    margin-top: 10px;
    color: #dadada;
}

.filterBx{
font-size: 12px;
margin: 5px 2px;
background-color: rgb(51, 57, 65);
padding: 3px 5px;
border-radius: 3px;
border: 2px solid #6d6d6d;
cursor: pointer;
}

.activeFilt{
    background-color: rgb(25, 98, 194);
    border: 2px solid #04265C;
}