.athHist{
    width: 550px;
    height: 240px;
    padding-bottom: 75px;
}

.titleContainer{
    background-color: rgb(30, 81, 163);
    color: #ffffff;
    font-family: "Avega-Italic";
}

.AHSubTitle{
    padding: 5px;
    /* background-image: linear-gradient(rgb(19, 53, 108), rgb(16, 76, 173), rgb(19, 53, 108)); */
    font-size: 24px;
    color: #ffffff;
    font-family: "Avega-Italic";
    display: flex;
    align-items: center;
    justify-content: space-around;
    }
    
    .LCscoreboardTable td{
    padding: 3px;
    }
    
.athHistTable th{
    font-weight: normal;
}

    .AthHistHeader{
    background-color: rgba(4, 38,92, 0.95);
    color: #ffffff;
    font-size: 14px;
    font-family: "Avega-Italic";
    font-weight: normal;
    }
    
    .LCtableHeader th{
    padding: 5px;
    }
    
    .LCrankCell{
    background-color: rgba(4, 38,92, 0.95);
    color: #ffffff;
    font-weight: bold;
    font-family: "Avega-Italic";
    width: 10px;
    }
    .AthHistGymCell{
    background-color: #66D849;
    color: #04265C;
    font-family: "Avega-Italic";
    text-align: left;
    width: 200px;
    font-size: 14px;
    padding-left: 10px;
    text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; 
    }
    .AthHistCell{
    background-color: rgba(148,194,228, 0.95);
    color: #04265C;
    font-weight: bold;
    font-family: "Avega-Italic";
    width: 10px;
    font-size: 14px;
    }

    .graphicContainer{
        border: 1px solid rgb(150,150,150); 
        height: 720px; 
        width: 1260px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }