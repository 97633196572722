.botLBContain{
    width: 1400px;
    padding: 5px 0px;
}

.bottomLBFlexRow{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 2px solid rgb(4, 21, 49);
}

.botLBAthlete{
    font-family: "Avega-Italic";
}

.botLBAthTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #ffffff;
    padding: 2px 10px;
    width: 232px;
    font-size: 14px;
}

.botLBAthInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    /* background-color: rgb(4, 21, 49); */
    color: #ffffff;
    align-items: center;
    font-size: 14px;
    padding: 0px 5px;
}

.ovrLBAthInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* background-color: rgb(4, 21, 49); */
    color: #ffffff;
    align-items: center;
    flex-grow: 1;
    /* padding: 0px 5px; */
}

.activeAthlete{
    background-color: rgb(88, 185, 64);
    background-image: linear-gradient(rgb(41, 105, 25),  rgb(88, 185, 64), rgb(113, 212, 89));
    box-shadow: 12px 0 15px -4px rgb(41, 105, 25) inset, -12px 0 8px -4px rgb(41, 105, 25) inset;
}

.nonActiveAthlete{
    background-image: linear-gradient(rgb(11, 40, 87),  rgb(30, 81, 163), rgb(42, 95, 180));
    box-shadow: 12px 0 15px -4px rgb(11, 41, 90) inset, -12px 0 8px -4px rgb(11, 41, 90) inset;
}

.bubbleAthlete{
    background-image: linear-gradient(rgb(61, 61, 61),  rgb(105, 105, 105), rgb(141, 141, 141));
    box-shadow: 12px 0 15px -4px rgb(61, 61, 61) inset, -12px 0 8px -4px rgb(61, 61, 61) inset;
}

.botLBNum{
    border-bottom: 1px solid rgb(235, 235, 235);
    height: 15px;
    width: 15px;
    text-align: center;
    font-size: 12px;
}

.botLBName{
    margin-right: 10px;
    
}

.botLBLab{
    font-size: 14px;
}


.botOvrContainer{
    margin: 0px 2px;
}


/* OLD xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */

/* .botLBContain{
    width: 1240px;
    padding: 5px 10px;
}

.bottomLBFlexRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.botLBAthlete{
    font-family: "Avega-Italic";
}

.botLBAthTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #ffffff;
    padding: 2px 10px;
    width: 220px;
    font-size: 14px;
}

.botLBAthInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    background-color: rgb(4, 21, 49);
    color: #ffffff;
    align-items: center;
    font-size: 14px;
}

.ovrLBAthInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: rgb(4, 21, 49);
    color: #ffffff;
    align-items: center;
    flex-grow: 1;
}

.activeAthlete{
    background-color: rgb(88, 185, 64);
}

.nonActiveAthlete{
    background-color: rgb(30, 81, 163);
}

.bubbleAthlete{
    background-color: rgb(105, 105, 105);
}

.botLBNum{
    border-bottom: 1px solid rgb(235, 235, 235);
    height: 15px;
    width: 15px;
    text-align: center;
    font-size: 12px;
}

.botLBName{
    margin-right: 10px;
    
}

.botLBLab{
    font-size: 14px;
}


.botOvrContainer{
    margin: 0px 2px;
} */
